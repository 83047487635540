import stationMesure from "../assets/critGraphics/Station de mesure2.svg";

const stationSymbol = {
  type: "picture-marker",
  url: stationMesure,
  width: "48px",
  height: "48px",
};

export const stationGrenville = {
  coord: {
    type: "point",
    latitude: 45.6315143,
    longitude: -74.6083556
  },
  info: {
    nom: "Station Grenville"
  },
  code: "GR",
  debit: false,
  icone: stationSymbol,
  source: "CPRRO"
};

export const stationBaieQuesnel = {
  coord: {
    type: "point",
    latitude: 45.49919891,
    longitude: -74.28209686
  },
  info: {
    nom: "Station Baie Quesnel"
  },
  code: "BQ",
  debit: false,
  icone: stationSymbol,
  source: "Hydro Meteo"
};

export const stationRigaud = {
  coord: {
    type: "point",
    latitude: 45.49639893,
    longitude: -74.32589722
  },
  info: {
    nom: "Station Baie Rigaud"
  },
  code: "BR",
  debit: false,
  icone: stationSymbol,
  source: "Hydro Meteo"
};

export const stationStAndre = {
  coord: {
    type: "point",
    latitude: 45.54740143,
    longitude: -74.34999847
  },
  info: {
    nom: "Station STAA"
  },
  code: "SH",
  debit: false,
  icone: stationSymbol,
  source: "Hydro Meteo"
};

export const stationRiviereRigaud = {
  coord: {
    type: "point",
    latitude: 45.49409866,
    longitude: -74.29989624
  },
  info: {
    nom: "Station Rivière Rigaud"
  },
  code: "RR",
  debit: false,
  icone: stationSymbol,
  source: "Hydro Meteo"
};

export const stationCarillon = {
  coord: {
    type: "point",
    latitude: 45.56779861,
    longitude: -74.38359833
  },
  info: {
    nom: "Station Carillon"
  },
  code: "RI_M",
  debit: true,
  icone: stationSymbol,
  source: "CPRRO"
};