import { loadModules } from "esri-loader";
import config from "../config"

/* eslint no-restricted-globals: 0*/
const portalUrl = config.portalUrl
const redirectUrl = location.origin+'//callback'
const responseType = config.responseType
const apiServer = config.apiPath
let LOGIN_SUCCESS_PAGE = config.LOGIN_SUCCESS_PAGE;
let LOGIN_FAILURE_PAGE = config.LOGIN_FAILURE_PAGE;

class Auth {
    userProfile;

    getProfile() {
        if (localStorage.getItem("geosapiens_app")) {
            return {sub: "auth0|"+localStorage.getItem("geosapiens_app").substring(1)}
            // return jwtDecode(localStorage.getItem("id_token"));
        } else
            return {};
    }

    getIdToken() {
        if (localStorage.getItem("id_token")) {
            return localStorage.getItem("id_token");
        } else
            return {};
        //return this.idToken;
    }

    isAuthenticated() {
        return new Date().getTime() < localStorage.getItem("expires_at");
    }

    async signIn(username, password) {
        // location.href = `${portalUrl}/sharing/rest/oauth2/authorize?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUrl}`
        return await fetch(`${apiServer}/api/generateToken?username=${username}&password=${password}`)
            .then(res => res.json())
            .then(resJson => {
                return this.handleAuthentication(resJson)
            })

    
    }

    handleAuthentication(resJson) {
        if(resJson.token){

            localStorage.setItem("access_token", resJson.token);
            localStorage.setItem("username", resJson.username);
            localStorage.setItem("expires_at", "" + resJson.expires);
            // localStorage.setItem("refresh_token", resJson.refresh_token);
            // localStorage.setItem("refresh_token_expires_at", "" + (new Date().getTime() + parseInt(resJson.refresh_token_expires_in) * 1000));

            if(resJson.idApp){
                localStorage.setItem("geosapiens_app", "/" + resJson.idApp);
                LOGIN_SUCCESS_PAGE = localStorage.getItem("geosapiens_app")
            }
            let jsapiOAuth = {
                "server": portalUrl,
                "expires": localStorage.getItem("expires_at"),
                "ssl": true,
                "token": localStorage.getItem("access_token"),
                "userId": localStorage.getItem("username")
            }

            localStorage.setItem("myAppJSAPIOAuth", JSON.stringify(jsapiOAuth));
            location.href = LOGIN_SUCCESS_PAGE
            return true
            // resolve()
        }else{
            //location.href = LOGIN_FAILURE_PAGE;
            // return reject("no authentication done")
            return false
        }
        // return new Promise((resolve, reject) => {
            // this.auth0.parseHash((err, authResult) => {
            //     if (authResult && authResult.accessToken && authResult.idToken) {
            //         localStorage.setItem("access_token", authResult.accessToken);
            //         localStorage.setItem("id_token", authResult.idToken);
            //         localStorage.setItem("expires_at", authResult.idTokenPayload.exp * 1000);

            //         if (this.getProfile().sub) {
            //             localStorage.setItem("geosapiens_app", "/" + this.getProfile().sub.split("|")[1]);
            //             // LOGIN_SUCCESS_PAGE = localStorage.getItem("geosapiens_app")
            //         }

            //         location.hash = "";
            //         // location.pathname = LOGIN_SUCCESS_PAGE;

            //         resolve();
            //     } else if (err) {
            //         // location.pathname = LOGIN_FAILURE_PAGE;
            //         console.log(err);
            //         return reject(err);
            //     }
            // });
        //     debugger
        //     const url = location.href;
        //     const hash = url.substring(url.indexOf('?')+1);
        //     const params = hash.split("&").reduce((param1, param2) => {
        //         const [key, value] = param2.split("=");
        //         param1[key] = value 
        //         return param1;
        //     }, {})
        //     if (params.code) {
        //         fetch(`${apiServer}/api/token?code=${params.code}&redirect_uri=${redirectUrl}`).then(res => res.json()).then(resJson => {
        //             debugger
        //             if(resJson.access_token){

        //                 localStorage.setItem("access_token", resJson.access_token);
        //                 localStorage.setItem("username", resJson.username);
        //                 localStorage.setItem("expires_at", "" + (new Date().getTime() + parseInt(resJson.expires_in) * 1000));
        //                 localStorage.setItem("refresh_token", resJson.refresh_token);
        //                 localStorage.setItem("refresh_token_expires_at", "" + (new Date().getTime() + parseInt(resJson.refresh_token_expires_in) * 1000));
        //                 if(resJson.idApp){
        //                     localStorage.setItem("geosapiens_app", "/" + resJson.idApp);
        //                     LOGIN_SUCCESS_PAGE = localStorage.getItem("geosapiens_app")
        //                 }
        //                 let jsapiOAuth = {
        //                     "server": portalUrl,
        //                     "appId": clientId,
        //                     "expires": localStorage.getItem("expires_at"),
        //                     "ssl": true,
        //                     "token": localStorage.getItem("access_token"),
        //                     "userId": localStorage.getItem("username")
        //                 }
        
        //                 localStorage.setItem("myAppJSAPIOAuth", JSON.stringify(jsapiOAuth));
        //                 location.href = LOGIN_SUCCESS_PAGE
        //                 resolve()
        //             }else{
        //                 location.href = LOGIN_FAILURE_PAGE;
        //                 return reject("no authentication done")
        //             }
        //         }).catch(err => reject(err))
        //     } else {
        //         location.href = LOGIN_FAILURE_PAGE;
        //         return reject("no authentication done")
        //     }


        // })

    }

    signOut() {
        // clear id token, profile, and expiration

        localStorage.clear()
        location.href = "/"

        // this.auth0.logout({
        //     returnTo: 'https://webapp.e-nundation.com',
        //     // returnTo: 'https://webapp.geosapiens.ca',
        //     // returnTo: 'https://deuxmontagnes.geosapiens.ca',
        //     clientID: 'a1I1RdvVsiFijjRx3a175z1KUiRG59Au',
        // });
    }
}


export default Auth;