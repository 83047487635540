const dev = {
    apiPath: process.env.REACT_APP_API_URL
}

const test = {
    apiPath: process.env.REACT_APP_API_URL
}
const prod = {
    apiPath: process.env.REACT_APP_API_URL+"/b"
}


const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : (process.env.REACT_APP_STAGE === 'staging' ? test : dev);


const portalUrl = "https://geosapiens-agol.maps.arcgis.com"
const responseType = "code"
let LOGIN_SUCCESS_PAGE = "/secret";
let LOGIN_FAILURE_PAGE = "/";

export default {
  // Add common config values here
  portalUrl,
  responseType,
  LOGIN_FAILURE_PAGE,
  LOGIN_SUCCESS_PAGE,
  ...config
};