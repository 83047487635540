// import WL from "../assets/WL1430.png";
// import deuxTubes from "../assets/Deux_tubes.png";
// import troisTubes from "../assets/Trois_tubes.png";
// import pdf from "../assets/pdf/AirSolid_Installation_Documentation.pdf";
import "./popup.css";

export const buildingStatus = (feature) => {
  let div = document.createElement("div");
  div.className = "enun-popup";
  div.id = "building-popup";
  let etat = feature.graphic.attributes.ETAT;
  let hauteurEau = feature.graphic.attributes.H_eau_ter;
  let nbSacs = feature.graphic.attributes.Nb_Sacs;
  let lSacs = feature.graphic.attributes.longueur;
  let hSacs = feature.graphic.attributes.hauteur;
  let tooltipTxt = "";
  let tooltipSacsSable = "";
  if (typeof nbSacs !== "undefined") {
    tooltipSacsSable = "<p class='enun-popup-title'>Digue temporaire</p>" +
      "<li>Sacs de sable : <span style='color:red'><b>" + nbSacs + "</b></span></li>" +
      "<li>Longueur de la digue : <span style='color:red'><b>" + lSacs.toFixed(0) + "</b></span> m</li>" +
      "<li>Hauteur de la digue : <span style='color:red'><b>" + hSacs.toFixed(2) + "</b></span> m</li>"
  }
  // Batiments rouges
  if (etat === "Sous-sol et premier plancher inonde" || etat === "Sous-sol inonde" || etat === "Eau atteint le batiment") {
    tooltipTxt =
      "<li>Le bâtiment <span style='color:red'><b>est inondé</b></span></li>" +
      "<li>Profondeur d'eau maximale sur le terrain : <span style='color:red'><b>" + hauteurEau.toFixed(2) + "</b></span> m</li>" +

      //données aléatoire pour app Assomption demo
      // "<li>Dommage annuel moyen : <span style='color:red'><b>" + (500 + Math.random() * 1500).toFixed(0) + "</b></span> $</li>" +
      // "<li>Période de retour : <span style='color:red'><b>" + (2 + Math.random() * 198).toFixed(0) + "</b></span> années</li>" +
      // "<li>Personnes à évacuer : <span style='color:red'><b>" + (2 + Math.random() * 2).toFixed(0) + "</b></span></li>" +

      tooltipSacsSable
  }
  // Batiments jaune
  if (etat === "Isole (route inondee)") {
    tooltipTxt = "<li>Le bâtiment <span style='color:red'><b>est isolé (route inondée)</b></span> </li>"
  }
  div.innerHTML = tooltipTxt;
  return (
    div
  )
};

export const buildingStatusNoBags = (feature) => {
  let div = document.createElement("div");
  div.className = "enun-popup";
  div.id = "building-popup";
  let etat = feature.graphic.attributes.ETAT;
  let hauteurEau = feature.graphic.attributes.H_eau_ter;
  let tooltipTxt = "";
  // Batiments rouges
  if (etat === "Sous-sol et premier plancher inonde" || etat === "Sous-sol inonde" || etat === "Eau atteint le batiment") {
    tooltipTxt =
      "<li>Le bâtiment <span style='color:red'><b>est inondé</b></span></li>" +
      "<li>Profondeur d'eau maximale sur le terrain : <span style='color:red'><b>" + hauteurEau.toFixed(2) + "</b></span> m</li>"
  }
  // Batiments jaune
  if (etat === "Isole (route inondee)") {
    tooltipTxt = "<li>Le bâtiment <span style='color:red'><b>est isolé (route inondée)</b></span> </li>"
  }
  div.innerHTML = tooltipTxt;
  return (
    div
  )
};
// export const buildingStatusDefault = (feature) => {
//   let div = document.createElement("div");
//   div.className = "enun-popup";
//   div.id = "building-popup";
//   let etat = feature.graphic.attributes.ETAT;
//   let hauteurEau = feature.graphic.attributes.H_eau_ter;
//   let nbPerson = feature.graphic.attributes.Nb_person;
//   let courriel = feature.graphic.attributes.courriel;
//   let telephone = feature.graphic.attributes.telephone;
//   let nbSacs = feature.graphic.attributes.Nb_Sacs;
//   let lSacs = feature.graphic.attributes.longueur;
//   let hSacs = feature.graphic.attributes.hauteur;
//   // let valBat = feature.graphic.attributes.Valeur_bat;
//   // let dmgPc = feature.graphic.attributes.Dmg_pc;
//   // let dmgDollar = feature.graphic.attributes.Dmg_dollar;
//   // let dmgMoyPc = feature.graphic.attributes.Dmg_moy_pc;
//   // let dmgMoyDollar = feature.graphic.attributes.Dmg_moy;
//   // let recExacte = feature.graphic.attributes.rec_exacte;
//   // let nivRisque = feature.graphic.attributes.niv_risque;
//   // let buildingTable =
//   //   "<table>\n" +
//   //     "    <thead>\n" +
//   //     "        <tr>\n" +
//   //     "            <th colspan='3'>Information sur le bâtiment</th>\n" +
//   //     "        </tr>\n" +
//   //     "    </thead>\n" +
//   //     "    <tbody>\n" +
//   //     "        <tr>\n" +
//   //     "            <td>Valeur</td>\n" +
//   //     "            <td>" + valBat +"$</td>\n" +
//   //     "        </tr>\n" +
//   //     "        <tr>\n" +
//   //     "            <td>Dommages pour ce scénario</td>\n" +
//   //     "            <td>" + dmgDollar.toFixed(0) +"$</td>\n" +
//   //     "            <td>" + (dmgPc*100).toFixed(2) +"% de la valeur</td>\n" +
//   //     "        </tr>\n" +
//   //     "        <tr>\n" +
//   //     "            <td>Dommages annuels moyens</td>\n" +
//   //     "            <td>" + dmgMoyDollar.toFixed(0) +"$</td>\n" +
//   //     "            <td>" + (dmgMoyPc*100).toFixed(2) +"% de la valeur</td>\n" +
//   //     "        </tr>\n" +
//   //     "        <tr>\n" +
//   //     "            <td>Récurrence exacte</td>\n" +
//   //     "            <td>" + recExacte +" ans</td>\n" +
//   //     "            <td>Niveau de risque " + nivRisque +"</td>\n" +
//   //     "        </tr>\n" +
//   //     "    </tbody>\n" +
//   //     "</table>";
//   let tooltipTxt = "";
//   let tooltipSacsSable = "";
//   console.log(lSacs);
//   if (typeof lSacs !== "undefined") {
//     tooltipSacsSable =
//       "<li>Dommage annuel moyen : <span style='color:red'><b>" + (100 + Math.random() * 2900).toFixed(0) + "</b></span> $</li>" +
//       "<li>Sacs de sable : <span style='color:red'><b>" + nbSacs + "</b></span></li>" +
//       "<li>Longueur de la digue : <span style='color:red'><b>" + lSacs.toFixed(0) + "</b></span> m</li>" +
//       "<li>Hauteur de la digue : <span style='color:red'><b>" + hSacs.toFixed(2) + "</b></span> m</li>"
//   }
//   // Batiments rouges
//   if (etat === "Sous-sol et premier plancher inondé") {
//     // tooltipTxt = buildingTable +
//     tooltipTxt =
//       // "<li>Le sous-sol et le premier plancher <span style='color:red'><b>sont inondés</b></span></li>" +
//       "<li>Le bâtiment <span style='color:red'><b>est inondé</b></span></li>" +
//       "<li>Profondeur d'eau maximale sur le terrain : <span style='color:red'><b>" + hauteurEau.toFixed(2) + "</b></span> m</li>" +
//       "<li>Personne(s) à évacuer : <span style='color:red'><b>" + nbPerson + "</b></span></li>" + tooltipSacsSable +
//       "<i class='fa fa-envelope' style='font-size: 1em'></i>   <a href=mailto:" + courriel + ">" + courriel + "</a><br>" +
//       "<i class='fa fa-phone' style='font-size: 1em'></i>   " + telephone
//   }
//   // Batiments oranges
//   if (etat === "Sous-sol inondé" || etat === "Eau atteint le bâtiment") {
//     // tooltipTxt = buildingTable +
//     tooltipTxt =
//       // "<li>Le sous-sol <span style='color:red'><b>est inondé</b></span> </li>" +
//       "<li>Le bâtiment <span style='color:red'><b>est inondé</b></span> </li>" +
//       "<li>Profondeur d'eau maximale sur le terrain : <span style='color:red'><b>" + hauteurEau.toFixed(2) + "</b></span> m</li>" +
//       "<li>Personne(s) à évacuer : <span style='color:red'><b>" + nbPerson + "</b></span></li>" + tooltipSacsSable +
//       "<i class='fa fa-envelope' style='font-size: 1em'></i>   <a href=mailto:" + courriel + ">" + courriel + "</a><br>" +
//       "<i class='fa fa-phone' style='font-size: 1em'></i>   " + telephone
//   }
//   // Batiments rose
//   if (etat === "Isolé (route inondée)") {
//     // tooltipTxt = buildingTable +
//     tooltipTxt =
//       "<li>Le bâtiment <span style='color:red'><b>est isolé (route inondée)</b></span> </li>" +
//       "<li>Personne(s) à évacuer : <span style='color:red'><b>" + nbPerson + "</b></span></li>" +
//       "<i class='fa fa-envelope' style='font-size: 1em'></i>   <a href=mailto:" + courriel + ">" + courriel + "</a><br>" +
//       "<i class='fa fa-phone' style='font-size: 1em'></i>   " + telephone
//   }
//   div.innerHTML = tooltipTxt;
//   return (
//     div
//   )
// };

export const buildingFreestyleStatus = (feature) => {
  // let div = document.createElement("div");
  // div.className = "enun-popup";
  // div.id = "building-popup";
  // let nbPerson = feature.graphic.attributes.Nb_person;
  // let courriel = feature.graphic.attributes.courriel;
  // let telephone = feature.graphic.attributes.telephone;
  // div.innerHTML = "<li>Nombre de personnes : <span style='color:red'><b>" + nbPerson + "</b></span></li>" +
  //   "<i class='fa fa-envelope' style='font-size: 1em'></i>   <a href=mailto:" + courriel + ">" + courriel + "</a><br>" +
  //   "<i class='fa fa-phone' style='font-size: 1em'></i>   " + telephone;
  // return (
  //   div
  // )
};

export const streetStatus = (feature) => {
  let div = document.createElement("div");
  div.className = "enun-popup";
  div.id = "street-popup";
  let status = feature.graphic.attributes.STATUS;
  let hauteurEau = feature.graphic.attributes.MAX_PROF;
  let longSegment = feature.graphic.attributes.Shape_Leng;
  let tooltipTxt = "";
  // Routes jaune
  if (status === "Inonde - Praticable") {
    tooltipTxt = "<li>Ce tronçon de route <span style='color:red'><b>est inondé</b></span> (Praticable)</li>" +
      "<li>Profondeur d'eau maximale du segment : <span style='color:red'><b>" + hauteurEau.toFixed(2) + "</b></span> m</li>" +
      "<li>Longueur du segment : <b>" + longSegment.toFixed(0) + "</b> m</li>"
  }
  // Routes orange
  if (status === "Inonde - Vehicules urgence seulement") {
    tooltipTxt = "<li>Ce tronçon de route <span style='color:red'><b>est inondé</b></span> (Véhicule d'urgence seulement)</li>" +
      "<li>Profondeur d'eau maximale du segment : <span style='color:red'><b>" + hauteurEau.toFixed(2) + "</b></span> m</li>" +
      "<li>Longueur du segment : <b>" + longSegment.toFixed(0) + "</b> m</li>"
  }
  // Routes rouge
  if (status === "Inonde - Impraticable") {
    tooltipTxt = "<li>Ce tronçon de route <span style='color:red'><b>est inondé</b></span> (Impraticable)</li>" +
      "<li>Profondeur d'eau maximale du segment : <span style='color:red'><b>" + hauteurEau.toFixed(2) + "</b></span> m</li>" +
      "<li>Longueur du segment : <b>" + longSegment.toFixed(0) + "</b> m</li>"
  }
  div.innerHTML = tooltipTxt;
  return (
    div
  )
};

// Pour Sacs de sable
export const bagStatus = (feature) => {
  let div = document.createElement("div");
  div.className = "enun-popup";
  div.id = "bag-popup";
  let nbSacs = feature.graphic.attributes.Nb_Sacs;

  // if (nbSacs === undefined) {
  //   infoSacs = "<p>Information non disponible</p>";
  // } else {
  //   let volume = feature.graphic.attributes.Volume.toFixed(2);
  //   infoSacs = "<li>Nombre de sacs de sable: <span style='color:red'><b>" + nbSacs + "</b></span></li>"
  //     // "<li>Volume de sable: <span style='color:red'><b>" + volume + "</b></span> m³</li><br>";
  // }
  let lSacs = feature.graphic.attributes.longueur.toFixed(0);
  let hSacs = feature.graphic.attributes.hauteur.toFixed(2);

  let tooltipTxt = "<li>Sacs de sable: <span style='color:red'><b>" + nbSacs + "</b></span></li>" +
    "<li>Longueur de la digue: <span style='color:red'><b>" + lSacs + "</b></span> m</li>" +
    "<li>Hauteur de la digue: <span style='color:red'><b>" + hSacs + "</b></span> m</li>"
    // "<p class='enun-popup-title'>Sacs de sable</p>" + infoSacs
  div.innerHTML = tooltipTxt;
  return (
    div
  );
};

// // Pour Sacs de sable DIGUE EXPRESS
// export const bagStatus = (feature) => {
//   let div = document.createElement("div");
//   div.className = "enun-popup";
//   div.id = "bag-popup";
//   let nbSacs = feature.graphic.attributes.Nb_Sacs;
//   let tooltipTxt = "";
//   let infoSacs, hauteurMax, modele, image, temps, nbOper, budget;
//
//   if (nbSacs === undefined) {
//     infoSacs = "<p>Information non disponible</p>";
//   } else {
//     let volume = feature.graphic.attributes.Volume.toFixed(2);
//     infoSacs = "<li>Nombre de sacs de sable: <span style='color:red'><b>" + nbSacs + "</b></span></li>" +
//       "<li>Volume de sable: <span style='color:red'><b>" + volume + "</b></span> m³</li><br>";
//   }
//   let longueur = feature.graphic.attributes.longueur.toFixed(0);
//   let hauteur = feature.graphic.attributes.hauteur.toFixed(2);
//
//   if (hauteur <= 0.8) {
//     hauteurMax = 0.8;
//     modele = "2 bi-tubes Ø1000";
//     image = deuxTubes;
//     temps = 3.5;
//     nbOper = 3;
//     budget = 5000;
//   } else if (hauteur <= 1.25) {
//     hauteurMax = 1.25;
//     modele = "1 bi-tube Ø1000 avec 1 mono Ø600";
//     image = troisTubes;
//     temps = 5.5;
//     nbOper = 3;
//     budget = 7000;
//   } else if (hauteur <= 1.4) {
//     hauteurMax = 1.4;
//     modele = "1 bi-tube Ø1000 avec 1 mono Ø800";
//     image = troisTubes;
//     temps = 5.5;
//     nbOper = 3;
//     budget = 9000;
//   } else {
//     hauteurMax = 1.4;
//     modele = "1 bi-tube Ø1000 avec 1 mono Ø800";
//     image = troisTubes;
//     temps = 5.5;
//     nbOper = 3;
//     budget = 9000;
//   }
//   tooltipTxt =
//     infoSacs +
//     "<p class='enun-popup-title'>Digue Express®</p>" +
//     "<div class='model-container'><div class='model-text'>" + modele + "</div><img id='model-image' src=" + image + "></div>" +
//     "<li>Hauteur d'eau maximale prévue: <span style='color:red'><b>" + hauteur + "</b></span> m</li>" +
//     "<li>Hauteur de retenue maximale: <span style='color:red'><b>" + hauteurMax + "</b></span> m</li>" +
//     "<li>Longueur de la digue: <span style='color:red'><b>" + longueur + "</b></span> m</li>" +
//     "<li>Temps de mise en place: <span style='color:red'><b>" + temps + "</b></span> heures</li>" +
//     "<li>Nombre d'opérateurs: <span style='color:red'><b>" + nbOper + "</b></span></li>" +
//     "<li>Budget: <span style='color:red'><b>" + budget + "</b></span> $</li>" +
//     "<a href=" + pdf + " download='AirSolid_Installation_Documentation'>Télécharger les instructions et la documentation</a>";
//   div.innerHTML = tooltipTxt;
//   return (
//     div
//   );
// };