import React, {Component} from "react";
import {SearchBuilding} from "./SearchBuilding";
import StatsBar from "./BlockStats";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRoad, faWater, faChartBar, faSearchLocation, faRulerVertical, faWarehouse} from "@fortawesome/free-solid-svg-icons";
import {ReactComponent as Batiment} from '../assets/icons/ico_flodedhouse.svg';
import {ReactComponent as SacdeSable} from '../assets/icons/ico_sacsable.svg';
import * as actionCreators from "../store/actions";
import {connect} from "react-redux";


export class BottomPanel extends Component {
  render() {
    const btnPdf = document.getElementById("pdfBtn");
    const btnAlert = document.getElementById("alertBtn");
    if (btnPdf !== null && btnAlert !== null) {
      if (this.props.currentDataExport.length === 0) {
        btnPdf.style.display = "none";
        btnAlert.style.display = "none";
      } else {
        btnPdf.style.display = "block";
        btnAlert.style.display = "block";
      }
    }

    return (
      <div className="panel">
        <div id="btn-toggle-layer">
          <div className="btn-container-layer">

            <div className="left-panel-stat-container stats">
              <button
                id="btnStats"
                data-title="Statistiques"
                className={this.props.toggleStats ? "btnTrue" : "btnFalse"}
                onClick={this.props.onStatsToggle}
              >
                <FontAwesomeIcon icon={faChartBar}/>
              </button>
            </div>

            <div className="left-panel-stat-container">
              <button
                id="btnWater"
                data-title="Zones inondables"
                className={this.props.toggleWater ? "btnTrue" : "btnFalse"}
                onClick={this.props.onWaterToggle}
              >
                <FontAwesomeIcon icon={faWater}/>
              </button>
            </div>

            <div className="left-panel-stat-container">
              <button
                id="btnBuilding"
                data-title="Bâtiments"
                className={this.props.toggleBuilding ? "btnTrue" : "btnFalse"}
                onClick={this.props.onBuildingToggle}
              >
                <Batiment/>
              </button>
            </div>

            <div className="left-panel-stat-container">
              <button
                id="btnStreet"
                data-title="Routes"
                className={this.props.toggleStreet ? "btnTrue" : "btnFalse"}
                onClick={this.props.onStreetToggle}
              >
                <FontAwesomeIcon icon={faRoad}/>
              </button>
            </div>

            {/*<div className="left-panel-stat-container">*/}
            {/*  <button*/}
            {/*    id="btnBag"*/}
            {/*    data-title="Sacs de sable"*/}
            {/*    className={this.props.toggleBag ? "btnTrue" : "btnFalse"}*/}
            {/*    onClick={this.props.onBagToggle}*/}
            {/*    disabled={this.props.bagUrl === ""}*/}
            {/*  >*/}
            {/*    <SacdeSable/>*/}
            {/*  </button>*/}
            {/*</div>*/}

            <div className="left-panel-stat-container">
              <button
                id="btnService"
                data-title="Services essentiels"
                className={this.props.toggleService ? "btnTrue" : "btnFalse"}
                onClick={this.props.onServiceToggle}
                // disabled={this.props.serviceEssentiel_Url === ""}
              >
                <FontAwesomeIcon icon={faWarehouse}/>
              </button>
            </div>

            <div className="left-panel-stat-container">
              <button
                id="btnStation"
                data-title="Stations de mesures"
                className={this.props.toggleStation ? "btnTrue" : "btnFalse"}
                onClick={this.props.onStationToggle}
                // disabled={this.props.stationHydro_Url === ""}
              >
                <FontAwesomeIcon icon={faRulerVertical}/>
              </button>
            </div>


            <div className="left-panel-stat-container search">
              <button
                id="btn-search-ctrl"
                data-title="Recherche de bâtiments"
                className={this.props.toggleSearch ? "btnTrue" : "btnFalse"}
                onClick={this.props.onSearchToggle}
                // disabled={true}
              >
                <FontAwesomeIcon icon={faSearchLocation}/>
              </button>
            </div>

            <SearchBuilding labels={this.props.labels}/>

          </div>
        </div>

        <StatsBar
          scenarioDepart={this.props.scenarioDepart}
          labels={this.props.labels}
          dataKm={this.props.dataKm}
          dataSacs={this.props.dataSacs}
          dataIsole={this.props.dataIsole}
          // dataSS={this.props.dataSS}
          // dataPrem={this.props.dataPrem}
          dataTot={this.props.dataTot}
          dataEvac={this.props.dataEvac}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    setApp: state.app,
    toggleWater: state.water,
    toggleBuilding: state.building,
    toggleStreet: state.street,
    toggleBag: state.bag,
    toggleService: state.service,
    toggleStation: state.station,
    toggleStats: state.stats,
    toggleSearch: state.search,
    currentDataExport: state.dataExport
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onWaterToggle: () => dispatch(actionCreators.toggleWater()),
    onBuildingToggle: () => dispatch(actionCreators.toggleBuilding()),
    onStreetToggle: () => dispatch(actionCreators.toggleStreet()),
    onBagToggle: () => dispatch(actionCreators.toggleBag()),
    onServiceToggle: () => dispatch(actionCreators.toggleService()),
    onStationToggle: () => dispatch(actionCreators.toggleStation()),
    onStatsToggle: () => dispatch(actionCreators.toggleStats()),
    onSearchToggle: () => dispatch(actionCreators.toggleSearch())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(BottomPanel);