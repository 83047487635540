import React from "react";
import {connect} from "react-redux";
import * as actionCreators from "../store/actions";
import Header from "../components/Header";
import BottomPanel from "../components/BottomPanel";
import {WaterLevelMap} from "../components/WaterLevelMap";
import {BO} from "../constants/appsConstants";
import {Mentions} from "../components/Mentions";
import {Legende} from "../components/Legende";
import Validation from "../components/Validation";

export class AppBousalem extends React.Component {
  render() {
    this.props.onSetApp("BO");
    this.props.onTypeMesureChange();  //For "Niveau d'eau" instead of "debit"
    return (
      <div className="app">
        <Header
          scenarioDepart={BO.scenarioDepart}
          nbScenario={BO.nbScenario}
          labels={BO.labels}
          yearMarkers={BO.yearMarkers}
          signOut={this.props.auth.signOut}
        />

        <BottomPanel
          scenarioDepart={BO.scenarioDepart}
          labels={BO.labels}
          dataKm={BO.dataKm}
          dataSacs={BO.dataSacs}
          dataIsole={BO.dataIsole}
          dataTot={BO.dataTot}
          dataEvac={BO.dataEvac}
          bagUrl={BO.bagUrl}
          serviceEssentiel_Url={BO.serviceEssentiel_Url}
          stationHydro_Url={BO.stationHydro_Url}
        />

        <WaterLevelMap
          configuration={BO}
          labels={BO.labels}
          store={this.props.store}
        />
        <Legende/>
        {/*<Mentions/>*/}
        <Validation/>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSetApp: (id) => dispatch(actionCreators.setApp(id)),
    onTypeMesureChange: () => dispatch(actionCreators.changeTypeMesure())
  }
};

export default connect(null, mapDispatchToProps)(AppBousalem)