import React from "react";
import "./Zi.css";
import {connect} from "react-redux";
import * as actionCreators from "../store/actions";

const Zi = (props) => {
  return (
    <>
      {/*<button className={props.toggleZi2019 ? "btn-toggle-zi zi-on" : "btn-toggle-zi"} id="zi-2019"*/}
      {/*        onClick={props.onZi2019Toggle}*/}
      {/*        disabled={true}>*/}
      {/*  2019*/}
      {/*</button>*/}
      <button className={props.toggleZi2 ? "btn-toggle-zi zi-on" : "btn-toggle-zi"} id="zi-2"
              onClick={props.onZi2Toggle}
              >
        2000
      </button>
      <button className={props.toggleZi20 ? "btn-toggle-zi zi-on" : "btn-toggle-zi"} id="zi-20"
              onClick={props.onZi20Toggle}
              >
        2003
      </button>
      <button className={props.toggleZi100 ? "btn-toggle-zi zi-on" : "btn-toggle-zi"} id="zi-100"
              onClick={props.onZi100Toggle}
              >
        2012
      </button>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    toggleZi2019: state.zi2019,
    toggleZi2: state.zi2,
    toggleZi20: state.zi20,
    toggleZi100: state.zi100
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onZi2019Toggle: () => dispatch(actionCreators.toggleZi2019()),
    onZi2Toggle: () => dispatch(actionCreators.toggleZi2()),
    onZi20Toggle: () => dispatch(actionCreators.toggleZi20()),
    onZi100Toggle: () => dispatch(actionCreators.toggleZi100())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Zi);