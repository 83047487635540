import React from 'react';
import ReactDOM from 'react-dom';
import { createStore , applyMiddleware} from 'redux';
// import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import "./index.css";

//import App from "./containers/App"; //pour PRODUCTION
//import App from "./containers/AppAssomption"; //pour dev Assomption
// import App from "./containers/AppAssomptionNiveau"; //pour dev Assomption Niveau
// import App from "./containers/AppAssomptionNDPJ"; //pour dev Assomption NDPJ
// import App from "./containers/AppAssomptionSCB"; //pour dev Assomption SCB
// import App from "./containers/AppDeuxMontagnes"; //pour dev DeuxMontagnes
// import App from "./containers/AppMilleIles"; //pour dev MillesIles
// import App from "./containers/AppRigaud"; //pour dev Rigaud
// import App from "./containers/AppRigaudMunic"; //pour dev Rigaud munic
// import App from "./containers/AppStaaMunic"; //pour dev STAA munic
// import App from "./containers/AppChamplainMunic"; //pour dev Champlain munic
// import App from "./containers/AppChaudiere"; //pour dev Chaudiere
// import App from "./containers/AppLachuteMunic"; //pour dev Lachute
import App from "./containers/AppBousalem"; //pour dev Bousalem
import * as serviceWorker from "./utils/serviceWorker";
import Auth from "./utils/Auth";
import reducer from './store/reducer'

const auth = new Auth();

// const store = createStore(
//     reducer,
//     compose(
//         applyMiddleware(thunk),
//         window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//     )
// );
const composeEnhancers = composeWithDevTools({
    // options like actionSanitizer, stateSanitizer
  });
  const store = createStore(
    reducer,  
    composeEnhancers(
      applyMiddleware(thunk),
    )
  );
/*const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);*/

let state = {};
window.setState = (changes) => {
    state = Object.assign({}, state, changes);
    ReactDOM.render(
        <Provider store={store}>
            {/*{console.log("state", state)}*/}
            <App {...state} store={store}/>
        </Provider>,
        document.getElementById("root")
    );
};

/* eslint no-restricted-globals: 0*/

let username = auth.getProfile().sub || "Geosapiens";

let initialState = {
    name: username,
    location: location.pathname.replace(/^\/?|\/$/g, ""),
    auth,
};

window.setState(initialState);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
//serviceWorker.register();

